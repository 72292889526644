import Tablesort from 'tablesort';

export default class Table {
  constructor($tableContainer) {
    this.$el = $tableContainer;
    this.$table = this.$el.querySelector('table');
    this.$tooltips = this.$el.querySelectorAll('.tooltip__popup');
    this.$downloadCheckboxes = this.$el.querySelectorAll('.table__download-checkbox');

    if (this.$tooltips.length) {
      this.initTableTooltips();
    }

    if (this.$downloadCheckboxes.length) {
      this.initTableDownloadCheckboxes();
    }

    if (this.$el.classList.contains('table--sortable')) {
      this.initTableSortable();
    }

	if (this.$el.classList.contains('table--dynamic-sortable')) {
		const lg = '(min-width: 1024px)';
		const queries = window.matchMedia(lg);

		if (queries.matches) {
			this.initDynamicalTableSortable();
		}

		queries.addEventListener('change', event => {
			if (event.matches) {
				this.initDynamicalTableSortable();
			} else {
				this.table = null;
			}
		});
	}
  }

  initDynamicalTableSortable() {
	  this.table = new Tablesort(this.$table);

	  // Make TH's triggerable with the enter-key
	  const $tableHeads = this.$table.querySelectorAll('th[tabindex="0"]');
	  $tableHeads.forEach(($tableHead) => {
		  $tableHead.addEventListener('keyup', (e) => {
			  if (e.keyCode === 13) {
				  $tableHead.click();
			  }
		  });
	  });
  }

  initTableSortable() {
    Tablesort(this.$table);

    // Make TH's triggerable with the enter-key
    const $tableHeads = this.$table.querySelectorAll('th[tabindex="0"]');
    $tableHeads.forEach(($tableHead) => {
      $tableHead.addEventListener('keyup', (e) => {
        if (e.keyCode === 13) {
          $tableHead.click();
        }
      });
    });
  }

  initTableTooltips() {
    this.$tooltips.forEach(($tooltip) => {
      this.$el.append($tooltip);
    });
  }

  initTableDownloadCheckboxes() {
    this.onDownloadCheckboxClickBinded = this.onDownloadCheckboxClick.bind(this);

    this.$downloadCheckboxes.forEach(($checkbox) => {
      $checkbox.addEventListener('click', this.onDownloadCheckboxClickBinded);
    });
  }

  onDownloadCheckboxClick() {
    let checked = 0;

    this.$downloadCheckboxes.forEach(($checkbox) => {
      if ($checkbox.checked) {
        checked += 1;
      }
    });

    if (checked > 0) {
      document.querySelector('.table__button--download-selected').style.display = 'inline-flex';
    } else {
      document.querySelector('.table__button--download-selected').style.display = 'none';
    }
  }
}

document.querySelectorAll('.table').forEach($tableContainer => new Table($tableContainer));
