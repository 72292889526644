import h from 'hyperscript';
import classNames from 'classnames';

export default class FormValidation {
  constructor($form) {
    this.$form = $form;

    this.formStepsEdit = this.$form.classList.contains('form-steps__form--edit');

    this.isRequire = false;
    this.isEmail = false;
    this.isDate = false;
    this.isNum = false;
    this.isPhone = false;
    this.isPostalCode = false;

    this.bindedValidateForm = this.validateForm.bind(this);

    this.initialize();
  }

  initialize() {
    // Disable Browser based validation
    this.$form.setAttribute('novalidate', 'novalidate');

    this.$form.addEventListener('submit', this.bindedValidateForm);

    // Set validateable fields for the form
    this.setValidateableFields();

    this.numberFields.forEach(($field) => {
      const invalidChars = ['-', '+', 'e'];

      $field.addEventListener('input', () => {
        $field.value = $field.value.replace(/[e\+\-]/gi, ''); // eslint-disable-line
      });

      $field.addEventListener('keydown', (e) => {
        if (invalidChars.includes(e.key)) {
          e.preventDefault();
        }
      });
    });

    // Live validation rules
    // Triggered upon input, change, etc.
    this.requiredFields.forEach(($el) => {
      const $field = $el;
      if (
        $field.type === 'checkbox'
        || $field.type === 'radio'
      ) return;

      if (!$field.dataset.valid) $field.dataset.valid = 'false';

      $field.addEventListener('blur', () => {
        this.isRequire = this.checkRequired($field);
      });

      $field.addEventListener('input', () => {
        this.isRequire = this.checkRequired($field);
      });

      $field.addEventListener('change', () => {
        this.isRequire = this.checkRequired($field);
      });
    });

    this.requiredCheckboxes.forEach(($el) => {
      const $checkbox = $el;
      // $checkbox.dataset.valid = 'false';
      if (!$checkbox.dataset.valid) $checkbox.dataset.valid = 'false';

      $checkbox.addEventListener('change', () => {
        this.isRequire = this.checkRequiredCheckbox($checkbox);
      });
    });

    this.requiredRadios.forEach(($el) => {
      const $radio = $el;
      $radio.dataset.valid = 'false';
      if (!$radio.dataset.valid) $radio.dataset.valid = 'false';

      $radio.addEventListener('change', () => {
        this.isRequire = this.checkRequiredRadios($radio);
      });
    });

    this.emailFields.forEach(($el) => {
      const $field = $el;
      if (!$field.dataset.valid) $field.dataset.valid = 'false';

      $field.addEventListener('blur', () => {
        this.isEmail = this.checkEmail($field);
      });

      $field.addEventListener('input', () => {
        if ($field.value.length < 1) {
          this.isEmail = this.checkEmail($field);
        }
      });
    });

    this.dateFields.forEach(($el) => {
      const $field = $el;
      if (!$field.dataset.valid) $field.dataset.valid = 'false';

      $field.addEventListener('blur', () => {
        this.isDate = this.checkDate($field);
      });

      $field.addEventListener('change', () => {
        this.isDate = this.checkDate($field);
      });
    });

    this.numberFields.forEach(($el) => {
      const $field = $el;
      if (!$field.dataset.valid) $field.dataset.valid = 'false';

      $field.addEventListener('blur', () => {
        this.isNum = this.checkNumber($field);
      });
    });

    this.phoneFields.forEach(($el) => {
      const $field = $el;
      if (!$field.dataset.valid) $field.dataset.valid = 'false';

      $field.addEventListener('blur', () => {
        this.isPhone = this.checkPhone($field);
      });
    });

    this.postalCodeFields.forEach(($el) => {
      const $field = $el;
      if (!$field.dataset.valid) $field.dataset.valid = 'false';

      $field.addEventListener('blur', () => {
        this.isPostalCode = this.checkPostalCode($field);
      });
    });

    if (this.formStepsEdit) this.validateForm();
  }

  setValidateableFields() {
    this.requiredFields = this.$form.querySelectorAll('[required="required"]:not([disabled="disabled"])');
    this.emailFields = this.$form.querySelectorAll('[type="email"]:not([disabled="disabled"])');
    this.dateFields = this.$form.querySelectorAll('[type="date"]:not([disabled="disabled"])');
    this.numberFields = this.$form.querySelectorAll('[type="number"]:not([disabled="disabled"])');
    this.phoneFields = this.$form.querySelectorAll('[type="tel"]:not([disabled="disabled"])');
    this.requiredCheckboxes = this.$form.querySelectorAll('[type="checkbox"][required="required"]:not([disabled="disabled"])');
    this.requiredRadios = this.$form.querySelectorAll('[type="radio"][required="required"]:not([disabled="disabled"])');
    this.postalCodeFields = this.$form.querySelectorAll('[data-type="postalcode"]:not([disabled="disabled"])');
  }

  // Form-Action based validation rules
  // Triggered submit, and defined button click, etc.
  validateForm(event) {
    // Re-Set validateable fields (cause they could have changed)
    this.setValidateableFields();
    // Do not validate the form in case the submitter (submit Button)
    // has the formnovalidate attribute set. Otherwise proceed with client-side validation
    const formnovalidate = event && event.submitter.getAttribute('formnovalidate');
    if (formnovalidate) return;

    const requiredArr = [true];
    this.requiredFields.forEach($el => requiredArr.push(this.checkRequired($el)));
    this.requiredCheckboxes.forEach($el => requiredArr.push(this.checkRequiredCheckbox($el)));
    this.requiredRadios.forEach($el => requiredArr.push(this.checkRequiredRadios($el)));
    this.isRequire = !requiredArr.includes(false);

    const emailArr = [true];
    this.emailFields.forEach($el => emailArr.push(this.checkEmail($el)));
    this.isEmail = !emailArr.includes(false);

    const datesArr = [true];
    this.dateFields.forEach($el => datesArr.push(this.checkDate($el)));
    this.isDate = !datesArr.includes(false);

    const numbersArr = [true];
    this.numberFields.forEach($el => numbersArr.push(this.checkNumber($el)));
    this.isNum = !numbersArr.includes(false);

    const phoneArr = [true];
    this.phoneFields.forEach($el => phoneArr.push(this.checkPhone($el)));
    this.isPhone = !phoneArr.includes(false);

    const postalCodeArr = [true];
    this.postalCodeFields.forEach($el => postalCodeArr.push(this.checkPostalCode($el)));
    this.isPostalCode = !postalCodeArr.includes(false);

    const isFormValid = this.isRequire
      && this.isEmail
      && this.isDate
      && this.isNum
      && this.isPhone
      && this.isPostalCode;

    if (!isFormValid) {
      // Sidestep. If we are validating form-steps show an error on unsuccessful submit
      const $formSteps = document.querySelector('.form-steps');

      if ($formSteps) {
        const $formStepsHeaders = $formSteps.querySelector('.form-steps__headers');

        // Show error-message specifically for form-steps
        if (!$formSteps.querySelector('.form-steps__error-message')) {
          const errorMsg = h('.form-steps__error-message', { id: 'form-steps-error-message' },
            h('.form-steps__error-message-inner', 'Einige Schritte brauchen ihre Aufmerksamkeit. In Schritte gekennzeichnet mit einem Ausrufezeichen oder Kreuz befinden sich fehlerhafte oder unvollständige Angaben.'));
          $formStepsHeaders.after(errorMsg);
        }
        document.getElementById('form-steps-error-message').scrollIntoView();
      }
      if (event) event.preventDefault();
    }
  }

  // Check date value validity
  isDateValid(date) {
    if (!/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(date)) return false; // eslint-disable-line

    const parts = date.split('-');
    const day = parseInt(parts[2], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[0], 10);

    if (year > 9999 || month === 0 || month > 12) return false;

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  // Function for Date Field Validation
  checkDate($el) {
    const $field = $el;
    let valid = false;
    const date = $field.value.trim();

    if (date) {
      if (!this.isDateValid(date)) {
        this.showError($field, 'Bitte geben Sie ein gültiges Datum an.');
      } else {
        this.showSuccess($field);
        valid = true;
      }
    } else if (!$field.required) {
      valid = true;
    }

    return valid;
  }

  // Function for Email Field Validation
  checkEmail($el) {
    const $field = $el;
    const value = $field.value.trim();
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line

    let valid = false;

    if (value) {
      $field.value = value;

      if (!re.test(value)) {
        $field.dataset.valid = 'false';
        this.showError($field, 'Bitte geben Sie eine gültige E-Mail-Adresse an.');
      } else {
        $field.dataset.valid = 'true';
        this.showSuccess($field);
        valid = true;
      }
    } else if (!$field.required) {
      $field.dataset.valid = 'true';
      this.showSuccess($field);
      valid = true;
    }

    return valid;
  }

  // Function for Telephone Field Validation
  checkPhone($el) {
    const $field = $el;
    let valid = false;
    const phone = $field.value.trim();
    const re = /^([\+][0-9]{1,3}[ \.\-])?([\(]{1}[0-9]{1,6}[\)])?([0-9 \.\-\/]{3,20})((x|ext|extension)[ ]?[0-9]{1,4})?$/; // eslint-disable-line

    if (phone) {
      if (!re.test(phone)) {
        $field.dataset.valid = 'false';
        this.showError($field, 'Bitte geben Sie eine gültige Telefonnummer an.');
      } else {
        $field.dataset.valid = 'true';
        this.showSuccess($field);
        valid = true;
      }
    } else if (!$field.required) {
      valid = true;
    }

    return valid;
  }

  // Check german postalcode validity
  // isPostalCodeValid(postalcode) {
  //   const re = /\b(?!01000)(?!99999)(0[1-9]\d{3}|[1-9]\d{4})\b/g; // eslint-disable-line
  //   return re.test(postalcode);
  // }

  // Function for Postal Code Field Validation
  checkPostalCode($el) {
    const $field = $el;
    let valid = false;
    const postalCode = $field.value.trim();
    const re = /\b(?!01000)(?!99999)(0[1-9]\d{3}|[1-9]\d{4})\b/g; // eslint-disable-line

    if (postalCode) {
      if (!re.test(postalCode)) {
        $field.dataset.valid = 'false';
        this.showError($field, 'Bitte geben Sie eine gültige Postleitzahl an.');
      } else {
        $field.dataset.valid = 'true';
        this.showSuccess($field);
        valid = true;
      }
    } else if (!$field.required) {
      valid = true;
    }

    return valid;
  }

  // Function for Required Field Validation
  checkRequired($el) {
    const $field = $el;
    const fieldIsMultiselect = $field.classList.contains('multiselect__select');
    let fieldHasValue = $field.value.trim().length;

    if (fieldIsMultiselect) {
      fieldHasValue = $field.options.length;
    }

    let valid = false;

    if (fieldHasValue < 1) {
      $field.dataset.valid = 'false';
      if (fieldIsMultiselect) {
        this.showErrorMultiselect($field, 'Bitte treffen Sie ihre Auswahl.');
      } else {
        this.showError($field, 'Bitte füllen Sie dieses Feld aus.');
      }
    } else {
      $field.dataset.valid = 'true';

      if (fieldIsMultiselect) {
        this.showSuccessMultiselect($field);
      } else {
        this.showSuccess($field);
      }
      valid = true;
    }

    return valid;
  }

  checkRequiredCheckbox($el) {
    const $checkbox = $el;
    const checkedStatus = $checkbox.checked;

    let valid = false;

    if (!checkedStatus) {
      $checkbox.dataset.valid = 'false';
      this.showError($checkbox, '');
    } else {
      $checkbox.dataset.valid = 'true';
      this.showSuccess($checkbox);
      valid = true;
    }

    return valid;
  }

  checkRequiredRadios($el) {
    const $radio = $el;
    let checkedStatus = false;
    let valid = false;

    // Check all radio's with the same name and set status to true if one of them is checked
    const $radios = this.$form.querySelectorAll(`[name="${$radio.name}"]`);
    $radios.forEach(($item) => {
      if ($item.checked) {
        checkedStatus = true;
      }
    });

    if (!checkedStatus) {
      $radio.dataset.valid = 'false';
      this.showError($radio, 'Bitte wählen Sie eine Option.');
    } else {
      $radios.forEach(($item) => {
        // eslint-disable-next-line no-param-reassign
        $item.dataset.valid = 'true';
        this.showSuccess($item);
      });
      valid = true;
    }

    return valid;
  }

  // Function for Number Field Validation
  checkNumber($el) {
    const $field = $el;
    let valid = false;
    const value = $field.value.trim();

    if (value) {
      if (isNaN(value)) { // eslint-disable-line
        $field.dataset.valid = 'false';
        this.showError($field, 'Ihre Eingabe muss ein Zahlenwert sein.');
      } else {
        $field.dataset.valid = 'true';
        this.showSuccess($field);
        valid = true;
      }
    } else if (!$field.required) {
      valid = true;
    }

    return valid;
  }

  // Show Error Message if input not valid
  showError(input, message) {
    const formField = input.closest('.form-group');

    if (formField) {
      const errorId = `${formField.querySelector('.form-group__label').getAttribute('for')}-error`;

      input.setAttribute('aria-invalid', true);
      formField.querySelector('.form-group__label').classList.add('label--invalid');
      input.setAttribute('aria-errormessage', errorId);

      if (formField.querySelector('.form-group__error')) {
        formField.querySelector('.form-group__error').remove();
      }

      if (message) {
        const $errorMessage = h('p', {
          className: classNames('form-group__error'),
          id: errorId,
        }, message);

        formField.appendChild($errorMessage);
      }
    }
  }

  showErrorMultiselect(select, message) {
    const column = select.closest('.multiselect__col');

    if (column) {
      const errorId = `${column.querySelector('.label').getAttribute('for')}-error`;
      column.classList.add('multiselect__col--error');
      select.setAttribute('aria-invalid', true);
      column.querySelector('.label').classList.add('label--invalid');
      select.setAttribute('aria-errormessage', errorId);

      if (column.querySelector('.multiselect__error')) {
        column.querySelector('.multiselect__error').remove();
      }

      if (message) {
        const $errorMessage = h('p', {
          className: classNames('multiselect__error'),
          id: errorId,
        }, message);

        column.appendChild($errorMessage);
      }
    }
  }

  // Remove Error Message
  showSuccess(input) {
    const $items = this.$form.querySelectorAll(`[id=${input.id}]`);

    $items.forEach(($item) => {
      const formField = $item.closest('.form-group');

      if (formField) {
        $item.removeAttribute('aria-invalid');
        input.removeAttribute('aria-errormessage');
        formField.querySelector('.form-group__label').classList.remove('label--invalid');

        if (formField.querySelector('.form-group__error')) {
          formField.querySelector('.form-group__error').remove();
        }
      }
    });
  }

  showSuccessMultiselect(select) {
    const column = select.closest('.multiselect__col');

    column.classList.remove('multiselect__col--error');
    column.querySelector('.label').classList.remove('label--invalid');
    select.removeAttribute('aria-invalid');
    select.removeAttribute('aria-errormessage');

    if (column.querySelector('.multiselect__error')) {
      column.querySelector('.multiselect__error').remove();
    }
  }
}
